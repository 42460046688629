import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useKpisPermissions = function (user) {
    var hasPermissionListKpi = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.KPI, MethodCode.LIST);
    var hasPermissionEditKpi = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.KPI, MethodCode.EDIT);
    var hasPermissionViewKpi = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.KPI, MethodCode.VIEW);
    var hasPermissionDeleteKpi = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.KPI, MethodCode.DELETE);
    var hasPermissionCreateKpi = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.KPI, MethodCode.CREATE);
    return {
        hasPermissionListKpi: hasPermissionListKpi,
        hasPermissionEditKpi: hasPermissionEditKpi,
        hasPermissionViewKpi: hasPermissionViewKpi,
        hasPermissionDeleteKpi: hasPermissionDeleteKpi,
        hasPermissionCreateKpi: hasPermissionCreateKpi,
    };
};
export default useKpisPermissions;
